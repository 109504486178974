<template>
  <div>
    {{ bizBucket }}
    <b-form-group label="Tagged input using dropdown" label-for="tags-with-dropdown">
      <b-form-tags id="tags-with-dropdown" v-model="bizBucket" no-outer-focus class="mb-2">
        <template v-slot="{ tags, disabled, addTag, removeTag }">
          <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
            <li v-for="tag in tags" :key="tag.bizBucket" class="list-inline-item">
              <b-form-tag
                @remove="removeTag(tag)"
                :title="tag.text"
                :disabled="disabled"
                variant="info"
              >{{ JSON.parse(tag).text }}</b-form-tag>
            </li>
          </ul>

          <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
            <template #button-content>
              <b-icon icon="tag-fill"></b-icon> Choose tags
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                label="Search tags"
                label-for="tag-search-input"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
                :description="searchDesc"
                :disabled="disabled"
              >
                <b-form-input
                  v-model="search"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <div class="overflow-auto" style="max-height: 200px;">
            <b-dropdown-item-button
              v-for="option in availableOptions" :key="option.value"  @click="onOptionClick(option)">
              {{ option.text }}
            </b-dropdown-item-button>
            <b-dropdown-text v-if="availableOptions.length === 0">
              There are no tags available to select
            </b-dropdown-text>
          </div>

          </b-dropdown>
        </template>
      </b-form-tags>
    </b-form-group>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  data() {
    return {
      options: [],
      search: '',
      bizBucket: []
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const criteria = this.criteria
      // Filter out already selected options
      const options = this.options.filter(opt => !this.bizBucket.some(tag => tag.value === opt.value))
      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.text.toLowerCase().indexOf(criteria) > -1);
      }
      // Show all options available
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'There are no tags matching your search criteria'
      }
      return ''
    }
  },
  methods: {
    getData() {
      axios.get('/api/content/category/create')
        .then(response => {
          let resp = response.data;
          console.log("resp", resp)
          this.options = resp
         // this.options = response.data;

        })
        .catch(error => {
          console.error(error);
        });
    },
    onOptionClick(option) {
      this.bizBucket.push(option)
      this.search = ''
    },
    removeTag(tag) {
      const index = this.bizBucket.findIndex(t => t.value === tag.value)
      if (index !== -1) {
        this.bizBucket.splice(index, 1)
      }
    }
  }
}
</script>
